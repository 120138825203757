import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Shield, FileText, Lock, AlertCircle, ExternalLink, X } from 'lucide-react';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const slideUp = keyframes`
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const gradientBorder = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const pulseAnimation = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 122, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0); }
`;

const Container = styled.footer`
  background-color: #080808;
  color: #ffffff;
  position: relative;
  overflow: hidden;
  
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(0,122,255,0), #007aff, rgba(0,122,255,0));
    background-size: 200% 100%;
    animation: ${gradientBorder} 3s linear infinite;
  }
`;

const Wrapper = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  padding: 4rem 2rem;
`;

const DisclaimerSection = styled.div`
  background: linear-gradient(145deg, rgba(255,255,255,0.03), rgba(255,255,255,0.01));
  border-radius: 20px;
  padding: 2rem;
  margin-bottom: 3rem;
  border: 1px solid rgba(255,255,255,0.05);
  animation: ${fadeIn} 0.6s ease-out;
  
  &:hover {
    border-color: rgba(0,122,255,0.1);
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
  }
`;

const Title = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.5rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  color: #007aff;

  svg {
    animation: ${pulseAnimation} 2s infinite;
  }
`;

const DisclaimerText = styled.p`
  color: #b0b0b0;
  line-height: 1.8;
  font-size: 0.95rem;
  margin-bottom: 1.5rem;
`;

const Note = styled.div`
  background: rgba(0,122,255,0.05);
  border-left: 4px solid #007aff;
  padding: 1.5rem;
  border-radius: 0 12px 12px 0;
  margin-top: 2rem;

  h4 {
    color: #007aff;
    font-size: 1.1rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }

  p {
    color: #b0b0b0;
    line-height: 1.6;
    font-size: 0.95rem;
  }
`;

const LinksGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
`;

const LinkCard = styled.a`
  background: linear-gradient(145deg, rgba(255,255,255,0.02), rgba(255,255,255,0.01));
  border-radius: 16px;
  padding: 1.5rem;
  text-decoration: none;
  color: #ffffff;
  border: 1px solid rgba(255,255,255,0.05);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;

  &:hover {
    transform: translateY(-3px);
    border-color: rgba(0,122,255,0.2);
    box-shadow: 0 4px 20px rgba(0,0,0,0.2);
    background: linear-gradient(145deg, rgba(255,255,255,0.03), rgba(255,255,255,0.01));

    .icon {
      color: #007aff;
    }

    .arrow {
      transform: translateX(3px);
    }
  }

  .icon {
    padding: 0.75rem;
    background: rgba(0,122,255,0.1);
    border-radius: 12px;
    color: #b0b0b0;
    transition: all 0.3s ease;
  }

  .text {
    flex: 1;
    font-weight: 600;
  }

  .arrow {
    color: #007aff;
    transition: transform 0.3s ease;
  }
`;

const Bottom = styled.div`
  text-align: center;
  padding-top: 2rem;
  border-top: 1px solid rgba(255,255,255,0.05);
  color: #b0b0b0;
  font-size: 0.9rem;

  a {
    color: #007aff;
    text-decoration: none;
    
    &:hover {
      text-decoration: underline;
    }
  }
`;

const PolicyModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(8px);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  animation: ${fadeIn} 0.3s ease-out;
`;

const ModalContent = styled.div`
  background: linear-gradient(145deg, #12121c, #1a1a24);
  border-radius: 24px;
  width: 100%;
  max-width: 800px;
  max-height: 85vh;
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.4);
  animation: ${slideUp} 0.3s ease-out;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;

const ModalHeader = styled.div`
  padding: 1.5rem 2rem;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(0, 0, 0, 0.2);
`;

const ModalTitle = styled.h3`
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  gap: 0.75rem;

  svg {
    color: #007aff;
  }
`;

const CloseButton = styled.button`
  background: rgba(255, 255, 255, 0.05);
  border: none;
  width: 36px;
  height: 36px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #ffffff;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
    transform: rotate(90deg);
  }
`;

const ModalBody = styled.div`
  padding: 2rem;
  overflow-y: auto;
  color: #b0b0b0;
  line-height: 1.8;
  font-size: 1rem;
  white-space: pre-wrap;
`;

const LoadingSpinner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  color: #007aff;

  @keyframes spin {
    to { transform: rotate(360deg); }
  }

  &::before {
    content: '';
    width: 40px;
    height: 40px;
    border: 3px solid rgba(0, 122, 255, 0.1);
    border-top-color: #007aff;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
`;

const FooterSection = () => {
  const [modalContent, setModalContent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [activePolicy, setActivePolicy] = useState(null);

  const urls = {
    'terms': 'https://others.tevh.in/techvansh/terms-of-service.md',
    'privacy': 'https://others.tevh.in/techvansh/Privacy.md',
    'refund': 'https://others.tevh.in/techvansh/Refund.md',
    'course-terms': 'https://others.tevh.in/techvansh/course-terms.md'
  };

  const policyTitles = {
    'terms': 'Terms and Conditions',
    'privacy': 'Privacy Policy',
    'refund': 'Refund Policy',
    'course-terms': 'Course Terms'
  };

  const fetchPolicy = async (type) => {
    setLoading(true);
    setActivePolicy(type);
    
    try {
      const response = await fetch(urls[type]);
      const text = await response.text();
      setModalContent(text);
    } catch (error) {
      console.error('Error fetching policy:', error);
      setModalContent('Error loading policy. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalContent(null);
    setActivePolicy(null);
  };

  return (
    <Container>
      <Wrapper>
        <DisclaimerSection>
          <Title>
            <Shield size={24} /> Disclaimer
          </Title>
          <DisclaimerText>
            This site is not a part of the Facebook™ website or Facebook™ Inc. Additionally, This site is NOT endorsed by Facebook™ in any way. FACEBOOK™ is a trademark of FACEBOOK™, Inc. As stipulated by law, we can not and do not make any guarantees about your ability to get results or earn any money with our ideas, information, tools or strategies. We just want to help you by giving great content, direction and strategies that worked well for us and our students and that we believe can move you forward. All of our terms, privacy policies, and disclaimers for this program and website can be accessed via the link above. We feel transparency is important and we hold ourselves (and you) to a high standard of integrity. Thanks for stopping by. We hope this training and content brings you a lot of value.
          </DisclaimerText>
          
          <Note>
            <h4><AlertCircle size={20} /> Important Note</h4>
            <p>
              This is an educational purpose Course only. I didn't harm anyone I (Vansh Gurdaswani) used my own device during explaining this Course so please don't use this method for any kind of illegal or malicious activities because hacking is a crime if you do this then it's can land you in jail. I do not support any kind of illegal or malicious hacking.
            </p>
          </Note>
        </DisclaimerSection>

        <LinksGrid>
          <LinkCard onClick={() => fetchPolicy('terms')} href="#terms">
            <div className="icon">
              <FileText size={24} />
            </div>
            <span className="text">Terms and Conditions</span>
            <ExternalLink size={20} className="arrow" />
          </LinkCard>

          <LinkCard onClick={() => fetchPolicy('privacy')} href="#privacy">
            <div className="icon">
              <Lock size={24} />
            </div>
            <span className="text">Privacy Policy</span>
            <ExternalLink size={20} className="arrow" />
          </LinkCard>

          <LinkCard onClick={() => fetchPolicy('refund')} href="#refund">
            <div className="icon">
              <Shield size={24} />
            </div>
            <span className="text">Refund Policy</span>
            <ExternalLink size={20} className="arrow" />
          </LinkCard>

          <LinkCard onClick={() => fetchPolicy('course-terms')} href="#course-terms">
            <div className="icon">
              <FileText size={24} />
            </div>
            <span className="text">Course Terms</span>
            <ExternalLink size={20} className="arrow" />
          </LinkCard>
        </LinksGrid>

        <Bottom>
          © {new Date().getFullYear()} Android Hacking Course. All rights reserved. Created by <a href="#">Vansh Gurdaswani</a>
        </Bottom>

        {activePolicy && (
          <PolicyModal onClick={closeModal}>
            <ModalContent onClick={e => e.stopPropagation()}>
              <ModalHeader>
                <ModalTitle>
                  {activePolicy === 'terms' && <FileText size={24} />}
                  {activePolicy === 'privacy' && <Lock size={24} />}
                  {activePolicy === 'refund' && <Shield size={24} />}
                  {activePolicy === 'course-terms' && <FileText size={24} />}
                  {policyTitles[activePolicy]}
                </ModalTitle>
                <CloseButton onClick={closeModal}>
                  <X size={20} />
                </CloseButton>
              </ModalHeader>
              <ModalBody>
                {loading ? <LoadingSpinner /> : modalContent}
              </ModalBody>
            </ModalContent>
          </PolicyModal>
        )}
      </Wrapper>
    </Container>
  );
};

export default FooterSection;
