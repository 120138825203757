import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
import { Clock, ArrowRight, Check } from 'lucide-react';

// Add shine animation keyframe
const shineEffect = keyframes`
  0% {
    transform: translateX(-100%) rotate(25deg);
  }
  100% {
    transform: translateX(100%) rotate(25deg);
  }
`;

const android_mobilebar_float = keyframes`
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-4px); }
`;

const android_mobilebar_slideUp = keyframes`
  from { transform: translateY(100%); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
`;

const android_mobilebar_gradientShift = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const android_mobilebar_checkmark = keyframes`
  0% { transform: scale(0); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

// Rest of the styled components remain the same until EnrollButton
const StickBar = styled.div.attrs({ className: 'android-mobilebar_container' })`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(10, 10, 15, 0.98);
  backdrop-filter: blur(20px);
  display: none;
  flex-direction: column;
  z-index: 9999;
  animation: ${android_mobilebar_slideUp} 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: 0 -10px 40px rgba(0, 0, 0, 0.25);
  padding-bottom: env(safe-area-inset-bottom, 0);

  @media (max-width: 1023px) {
    display: flex;
  }
`;

const TimerSection = styled.div.attrs({ className: 'android-mobilebar_timer' })`
  background: rgba(0, 122, 255, 0.08);
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
`;

const TimerText = styled.div.attrs({ className: 'android-mobilebar_timer-text' })`
  color: #007aff;
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 8px;

  svg {
    animation: ${android_mobilebar_float} 2s ease infinite;
  }
`;

const TimeUnit = styled.div.attrs({ className: 'android-mobilebar_time-unit' })`
  background: linear-gradient(135deg, #007aff, #5856d6);
  padding: 4px 8px;
  border-radius: 6px;
  color: white;
  font-weight: 700;
  font-size: 14px;
  min-width: 28px;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 122, 255, 0.2);
  animation: ${android_mobilebar_float} 2s ease infinite;
  animation-delay: ${props => props.delay}s;
`;

const TimeLabel = styled.span.attrs({ className: 'android-mobilebar_time-label' })`
  color: white;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  margin-left: 4px;
`;

const MainContent = styled.div.attrs({ className: 'android-mobilebar_main' })`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  gap: 16px;
`;

const PriceSection = styled.div.attrs({ className: 'android-mobilebar_price' })`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const CurrentPrice = styled.div.attrs({ className: 'android-mobilebar_current-price' })`
  font-size: 28px;
  font-weight: 800;
  color: #00ff88;
  text-shadow: 0 0 20px rgba(0, 255, 136, 0.3);
`;

const OriginalPrice = styled.div.attrs({ className: 'android-mobilebar_original-price' })`
  color: #ff3b30;
  font-size: 15px;
  text-decoration: line-through;
  text-decoration-color: rgba(255, 59, 48, 0.7);
  opacity: 0.9;
`;

// Modified EnrollButton with shine effect
const EnrollButton = styled.button.attrs({ className: 'android-mobilebar_button' })`
  flex: 1;
  height: 48px;
  border: none;
  border-radius: 12px;
  background: linear-gradient(135deg, #0066ff, #5856d6);
  background-size: 200% 200%;
  color: white;
  font-weight: 700;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  animation: ${android_mobilebar_gradientShift} 3s ease infinite;
  box-shadow: 0 4px 15px rgba(0, 102, 255, 0.3);

  &:hover:not(:disabled) {
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 102, 255, 0.4);
  }

  &:active:not(:disabled) {
    transform: translateY(1px);
  }

  &.success {
    background: #00ff88;
    svg {
      animation: ${android_mobilebar_checkmark} 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    }
  }

  &.loading {
    background: linear-gradient(135deg, #0066ff, #5856d6);
  }

  &::after {
    content: '';
    position: absolute;
    top: -50%;
    left: -50%;
    right: -50%;
    bottom: -50%;
    background: linear-gradient(
      to right,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0) 100%
    );
    transform: translateX(-100%) rotate(25deg);
    animation: ${shineEffect} 1.5s infinite linear;
  }
`;

const LoadingSpinner = styled.div.attrs({ className: 'android-mobilebar_spinner' })`
  width: 20px;
  height: 20px;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-top: 2px solid white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`;

const DiscountBadge = styled.span.attrs({ className: 'android-mobilebar_badge' })`
  position: absolute;
  top: -10px;
  right: -10px;
  background: #ff3b30;
  color: white;
  font-size: 12px;
  font-weight: 700;
  padding: 4px 8px;
  border-radius: 20px;
  box-shadow: 0 4px 12px rgba(255, 59, 48, 0.3);
  animation: ${android_mobilebar_float} 2s infinite;
`;

// Utility functions remain the same
const encodeBase64 = (str) => {
  return btoa(str);
};

const decodeBase64 = (str) => {
  return atob(str);
};

const STORAGE_KEY = 'mobileStickyBarVisitTimestamp';

const saveTimestamp = (timestamp) => {
  const encoded = encodeBase64(timestamp.toString());
  localStorage.setItem(STORAGE_KEY, encoded);
};

const getTimestamp = () => {
  const encoded = localStorage.getItem(STORAGE_KEY);
  if (!encoded) return null;
  try {
    const decoded = decodeBase64(encoded);
    const timestamp = parseInt(decoded, 10);
    if (isNaN(timestamp)) return null;
    return timestamp;
  } catch (error) {
    console.error('Error decoding timestamp from localStorage:', error);
    return null;
  }
};

// Component implementation remains the same
const MobileStickyBar = () => {
  const [timeLeft, setTimeLeft] = useState({ hours: 48, minutes: 0, seconds: 0 });
  const [buttonState, setButtonState] = useState('idle');

  const calculateTimeLeft = (endTime) => {
    const now = Date.now();
    const difference = endTime - now;

    if (difference <= 0) {
      return { hours: 0, minutes: 0, seconds: 0 };
    }

    const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    const seconds = Math.floor((difference / 1000) % 60);

    return { hours, minutes, seconds };
  };

  useEffect(() => {
    let timerInterval;

    const initializeTimer = () => {
      const storedTimestamp = getTimestamp();
      const TWO_DAYS_MS = 2 * 24 * 60 * 60 * 1000;

      if (storedTimestamp) {
        const endTime = storedTimestamp + TWO_DAYS_MS;
        const now = Date.now();

        if (now < endTime) {
          setTimeLeft(calculateTimeLeft(endTime));
          timerInterval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(endTime);
            setTimeLeft(newTimeLeft);

            if (newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
              clearInterval(timerInterval);
              const newEndTime = Date.now() + TWO_DAYS_MS;
              saveTimestamp(Date.now());
              setTimeLeft(calculateTimeLeft(newEndTime));
              initializeTimer();
            }
          }, 1000);
        } else {
          const newEndTime = Date.now() + TWO_DAYS_MS;
          saveTimestamp(Date.now());
          setTimeLeft(calculateTimeLeft(newEndTime));
          timerInterval = setInterval(() => {
            const newTimeLeft = calculateTimeLeft(newEndTime);
            setTimeLeft(newTimeLeft);

            if (newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
              clearInterval(timerInterval);
              const refreshedEndTime = Date.now() + TWO_DAYS_MS;
              saveTimestamp(Date.now());
              setTimeLeft(calculateTimeLeft(refreshedEndTime));
              initializeTimer();
            }
          }, 1000);
        }
      } else {
        const endTime = Date.now() + TWO_DAYS_MS;
        saveTimestamp(Date.now());
        setTimeLeft(calculateTimeLeft(endTime));
        timerInterval = setInterval(() => {
          const newTimeLeft = calculateTimeLeft(endTime);
          setTimeLeft(newTimeLeft);

          if (newTimeLeft.hours === 0 && newTimeLeft.minutes === 0 && newTimeLeft.seconds === 0) {
            clearInterval(timerInterval);
            const newEndTime = Date.now() + TWO_DAYS_MS;
            saveTimestamp(Date.now());
            setTimeLeft(calculateTimeLeft(newEndTime));
            initializeTimer();
          }
        }, 1000);
      }
    };

    initializeTimer();

    return () => clearInterval(timerInterval);
  }, []);

  useEffect(() => {
    window.onpopstate = () => setButtonState('idle');
    return () => (window.onpopstate = null);
  }, []);

  const handleEnrollClick = () => {
    setButtonState('loading');
    setTimeout(() => {
      setButtonState('success');
      setTimeout(() => {
        window.location.href = 'https://web.tevh.in/checkout-android-course';
      }, 800);
    }, 1500);
  };

  return (
    <StickBar>
      <TimerSection>
        <TimerText>
          <Clock size={16} />
          Offer ends in:
        </TimerText>
        <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
          <TimeUnit delay={0}>
            {String(timeLeft.hours).padStart(2, '0')}
            <TimeLabel>h</TimeLabel>
          </TimeUnit>
          <TimeUnit delay={0.1}>
            {String(timeLeft.minutes).padStart(2, '0')}
            <TimeLabel>m</TimeLabel>
          </TimeUnit>
          <TimeUnit delay={0.2}>
            {String(timeLeft.seconds).padStart(2, '0')}
            <TimeLabel>s</TimeLabel>
          </TimeUnit>
        </div>
      </TimerSection>

      <MainContent>
        <PriceSection>
          <CurrentPrice>₹699</CurrentPrice>
          <OriginalPrice>₹2000</OriginalPrice>
        </PriceSection>

        <EnrollButton
          onClick={handleEnrollClick}
          disabled={buttonState === 'loading' || buttonState === 'success'}
          className={buttonState}
        >
          {buttonState === 'loading' && <LoadingSpinner />}
          {buttonState === 'success' && <Check size={24} />}
          {buttonState === 'idle' && (
            <>
              Enroll Now
              <ArrowRight size={20} />
              <DiscountBadge>65.5% OFF</DiscountBadge>
            </>
          )}
        </EnrollButton>
      </MainContent>
    </StickBar>
  );
};

export default MobileStickyBar;