import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Shield, UserCog, Syringe, Lock, Shield as SecurityIcon, UserX } from 'lucide-react';

const shimmer = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const float = keyframes`
  0% { transform: translateY(0); }
  50% { transform: translateY(-8px); }
  100% { transform: translateY(0); }
`;

const pulse = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0.4); }
  70% { box-shadow: 0 0 0 10px rgba(0, 122, 255, 0); }
  100% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0); }
`;

const Section = styled.section`
  padding: 6rem 2rem;
  background-color: #0a0a0a;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, transparent, #007aff, #5856d6, #007aff, transparent);
    background-size: 200% 100%;
    animation: ${shimmer} 3s linear infinite;
  }

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }
`;

const Container = styled.div`
  max-width: 1400px;
  margin: 0 auto;
`;

const TitleWrapper = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }
`;

const Title = styled.h2`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1.5rem;
  color: #ffffff;
  display: inline-block;
  
  span {
    color: #007aff;
    background: linear-gradient(45deg, #007aff, #5856d6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: ${pulse} 2s infinite;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }
`;

const Subtitle = styled.p`
  color: #b0b0b0;
  font-size: 1.25rem;
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.6;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 2rem;
  padding: 0.5rem;

  @media (min-width: 1200px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

const Card = styled.div`
  background: rgba(18, 18, 24, 0.6);
  border-radius: 24px;
  padding: 2rem;
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);
  animation: ${float} 6s ease-in-out infinite;
  animation-delay: ${props => props.index * 0.2}s;

  &:hover {
    transform: translateY(-5px);
    border-color: #007aff;
    background: rgba(0, 122, 255, 0.05);
    box-shadow: 
      0 8px 30px rgba(0, 122, 255, 0.15),
      inset 0 1px 1px rgba(255, 255, 255, 0.1);

    .icon-wrapper {
      background: linear-gradient(135deg, #007aff, #5856d6);
      transform: scale(1.1);
      box-shadow: 0 0 20px rgba(0, 122, 255, 0.3);

      svg {
        color: white;
        transform: scale(1.1);
      }
    }

    .card-title {
      color: #007aff;
    }

    .card-description {
      color: #ffffff;
    }
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 3px;
    background: linear-gradient(90deg, #007aff, #5856d6);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover::before {
    opacity: 1;
  }
`;

const IconWrapper = styled.div.attrs({ className: 'icon-wrapper' })`
  width: 70px;
  height: 70px;
  background: rgba(0, 122, 255, 0.1);
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 1.5rem;
  transition: all 0.3s cubic-bezier(0.16, 1, 0.3, 1);

  svg {
    width: 35px;
    height: 35px;
    color: #007aff;
    transition: all 0.3s ease;
  }
`;

const CardTitle = styled.h3.attrs({ className: 'card-title' })`
  font-size: 1.4rem;
  font-weight: 700;
  color: #ffffff;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
  line-height: 1.4;
`;

const CardDescription = styled.p.attrs({ className: 'card-description' })`
  color: #8e8e93;
  font-size: 1.1rem;
  line-height: 1.6;
  transition: color 0.3s ease;
  margin: 0;
`;

const learningPoints = [
  {
    icon: Shield,
    title: "Learn Methods to Hack Android Phones",
    description: "Master powerful Android security testing and penetration techniques through step-by-step, practical learning."
  },
  {
    icon: UserCog,
    title: "Strategy and Planning for Ethical Hacking",
    description: "Develop expert-level, systematic approaches for ethical hacking and security assessments with proven, practical methods."
  },
  {
    icon: Syringe,
    title: "Learn to Inject Malware in Apps",
    description: "Understand practical malware injection techniques and explore countermeasures to improve your security knowledge."
  },
  {
    icon: Lock,
    title: "Protect Yourself from Hackers!",
    description: "Discover essential defense strategies and apply strong security measures to prevent hacking attacks."
  },
  {
    icon: SecurityIcon,
    title: "Bypass Antivirus Software Easily",
    description: "Master antivirus evasion tactics and reinforce your security implementation skills with real-world methods."
  },
  {
    icon: UserX,
    title: "Tricks & Tips for Ethical Hacking",
    description: "Learn advanced tips and professional techniques to enhance your ethical hacking expertise."
  }
];


const WhatYouLearn = () => {
  return (
    <Section>
      <Container>
        <TitleWrapper>
          <Title>
            What You Will <span>Learn?</span>
          </Title>
          <Subtitle>
            Master the art of Android security with our comprehensive course modules 
            designed for both beginners and advanced users.
          </Subtitle>
        </TitleWrapper>
        <Grid>
          {learningPoints.map((point, index) => (
            <Card key={index} index={index}>
              <IconWrapper>
                <point.icon />
              </IconWrapper>
              <CardTitle>{point.title}</CardTitle>
              <CardDescription>{point.description}</CardDescription>
            </Card>
          ))}
        </Grid>
      </Container>
    </Section>
  );
};

export default WhatYouLearn;
