import React, { useState } from 'react';
import {
  ChevronDown,
  Clock,
  PlayCircle,
  Users,
  Video,
  BookOpen,
  Check,
  Lock,
  Star,
} from 'lucide-react';
import styled, { keyframes, css } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const spinAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const checkmarkAnimation = keyframes`
  0% {
    stroke-dashoffset: 50;
  }
  100% {
    stroke-dashoffset: 0;
  }
`;

const Container = styled.section`
  min-height: 100vh;
  background-color: #0a0a0a;
  color: #ffffff;
  padding: 4rem 2rem;
  position: relative;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, rgba(0, 122, 255, 0), #007aff, rgba(0, 122, 255, 0));
  }

  @media (max-width: 768px) {
    padding: 2rem 1rem;
  }
`;

const Title = styled.h2`
  font-size: 3rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 3rem;
  position: relative;

  span {
    background: linear-gradient(90deg, #007aff, #5856d6);
    background-size: 200% 200%;
    animation: ${gradientAnimation} 3s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
    margin-bottom: 2rem;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  gap: 3rem;
  max-width: 1400px;
  margin: 0 auto;
  animation: ${fadeIn} 0.8s ease-out;
  position: relative;

  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
`;

const AccordionContainer = styled.div`
  flex: 1;
  max-width: 800px;
  width: 100%;
`;

const AccordionItem = styled.div`
  background: linear-gradient(145deg, #12121c, #1a1a24);
  border-radius: 1rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 30px rgba(0, 122, 255, 0.15);
    border-color: rgba(0, 122, 255, 0.1);
  }
`;

const AccordionHeader = styled.div`
  padding: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  background: rgba(255, 255, 255, 0.02);
  user-select: none;

  .left-content {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  .right-content {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    color: #9ca3af;
    font-size: 0.875rem;
  }

  .module-badge {
    background: rgba(0, 122, 255, 0.1);
    color: #007aff;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-weight: 500;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &:hover {
    background: rgba(255, 255, 255, 0.03);
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .right-content {
      font-size: 0.8rem;
      gap: 1rem;
    }
  }
`;

const AccordionContent = styled.div`
  max-height: ${(props) => (props.isOpen ? '2000px' : '0')};
  overflow: hidden;
  transition: max-height 0.5s cubic-bezier(0.4, 0, 0.2, 1);
`;

const LectureItem = styled.div`
  padding: 1.25rem 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  transition: background-color 0.3s ease;

  .lecture-info {
    display: flex;
    align-items: center;
    gap: 1rem;
    flex: 1;
  }

  .lecture-title {
    font-weight: 500;
    color: #e5e7eb;
  }

  .lecture-meta {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #9ca3af;
    font-size: 0.875rem;
  }

  &:hover {
    background: rgba(255, 255, 255, 0.03);
  }

  @media (max-width: 768px) {
    padding: 1rem;

    .lecture-title {
      font-size: 0.95rem;
    }

    .lecture-meta {
      font-size: 0.8rem;
      gap: 0.5rem;
    }
  }
`;

const SidebarContainer = styled.div`
  flex: 0 0 400px;
  position: sticky;
  top: 2rem;
  height: fit-content;
  background: linear-gradient(145deg, #12121c, #1a1a24);
  border-radius: 1.5rem;
  padding: 2rem;
  animation: ${fadeIn} 0.8s ease-out 0.2s backwards;
  border: 1px solid rgba(255, 255, 255, 0.05);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);

  @media (max-width: 1200px) {
    width: 100%;
    max-width: 500px;
    position: relative;
    top: 0;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    padding: 1.5rem;
  }
`;

const PriceTag = styled.div`
  text-align: center;
  margin: 2rem 0;

  .original-price {
    color: #9ca3af;
    text-decoration: line-through;
    font-size: 1.5rem;
    margin-right: 1rem;
  }

  .current-price {
    font-size: 3rem;
    font-weight: 800;
    color: #007aff;
    text-shadow: 0 0 20px rgba(0, 122, 255, 0.3);
  }

  .discount-badge {
    background: rgba(220, 38, 38, 0.1);
    color: #dc2626;
    padding: 0.25rem 0.75rem;
    border-radius: 1rem;
    font-size: 0.875rem;
    font-weight: 600;
    margin-left: 1rem;
  }

  @media (max-width: 768px) {
    margin: 1.5rem 0;

    .original-price {
      font-size: 1.25rem;
    }

    .current-price {
      font-size: 2.5rem;
    }
  }
`;

const EnrollButton = styled.button`
  position: relative;
  width: 100%;
  padding: 1.25rem 2rem;
  font-size: 1.125rem;
  font-weight: 700;
  color: white;
  background: ${(props) =>
    props.success ? '#10b981' : 'linear-gradient(90deg, #007aff, #5856d6)'};
  background-size: 200% 200%;
  animation: ${(props) =>
    props.success ? 'none' : css`${gradientAnimation} 3s ease infinite`};
  border: none;
  border-radius: 1rem;
  cursor: ${(props) => (props.loading ? 'not-allowed' : 'pointer')};
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 1px;
  box-shadow: ${(props) =>
    props.success
      ? '0 8px 20px rgba(16, 185, 129, 0.3)'
      : '0 8px 20px rgba(0, 122, 255, 0.3)'};

  &:hover {
    transform: translateY(-2px);
    box-shadow: ${(props) =>
      props.success
        ? '0 12px 28px rgba(16, 185, 129, 0.4)'
        : '0 12px 28px rgba(0, 122, 255, 0.4)'};
  }

  &:active {
    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.8;
    transform: none;
    box-shadow: none;
  }

  display: flex;
  align-items: center;
  justify-content: center;

  .loader {
    border: 3px solid rgba(255, 255, 255, 0.3);
    border-top: 3px solid white;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    animation: ${spinAnimation} 1s linear infinite;
  }

  .success-icon {
    width: 24px;
    height: 24px;
    stroke: white;
    stroke-width: 2;
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    stroke-dasharray: 50;
    stroke-dashoffset: 50;
    animation: ${checkmarkAnimation} 0.7s forwards;
  }

  @media (max-width: 768px) {
    padding: 1rem 1.5rem;
    font-size: 1rem;
  }
`;

const CourseStats = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1.5rem;
  margin: 2rem 0;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.03);
  border-radius: 1rem;
  border: 1px solid rgba(255, 255, 255, 0.05);

  .stat-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 0.5rem;
  }

  .stat-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #007aff;
  }

  .stat-label {
    font-size: 0.875rem;
    color: #9ca3af;
  }

  @media (max-width: 768px) {
    gap: 1rem;
    padding: 1rem;

    .stat-value {
      font-size: 1.25rem;
    }

    .stat-label {
      font-size: 0.8rem;
    }
  }
`;

const CourseFeatures = styled.div`
  margin-top: 2rem;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 1.5rem;
    color: #e5e7eb;
  }

  .feature-list {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .feature-item {
    display: flex;
    align-items: center;
    gap: 1rem;
    color: #9ca3af;

    svg {
      color: #10b981;
    }
  }

  @media (max-width: 768px) {
    h3 {
      font-size: 1.1rem;
      margin-bottom: 1rem;
    }

    .feature-item {
      gap: 0.75rem;
      font-size: 0.9rem;
    }
  }
`;

const CourseImage = styled.div`
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  margin-bottom: 2rem;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);

  img {
    width: 100%;
    height: auto;
    display: block;
    transition: transform 0.3s ease;
  }

  &:hover img {
    transform: scale(1.05);
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.3) 0%,
      rgba(0, 0, 0, 0) 50%
    );
  }

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

const RatingBadge = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;
  background: rgba(0, 0, 0, 0.75);
  padding: 0.5rem 0.75rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  z-index: 1;

  .star-icon {
    color: #fbbf24;
  }

  .rating-text {
    color: white;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    top: 0.75rem;
    right: 0.75rem;
    padding: 0.4rem 0.6rem;

    .rating-text {
      font-size: 0.875rem;
    }
  }
`;

const CourseContentSection = (props) => {
  const { id, ...rest } = props; // Destructure id and any other props
  const [openModule, setOpenModule] = useState(0);
  const [buttonState, setButtonState] = useState('default');

  const handleEnrollClick = () => {
    if (buttonState !== 'default') return;

    setButtonState('loading');
    setTimeout(() => {
      setButtonState('success');
      setTimeout(() => {
        // Redirect user here
        window.location.href = 'https://web.tevh.in/checkout-android-course';
      }, 1000); // Wait 1 second after showing success
    }, 1500); // Show loading for 1.5 seconds
  };

  const modules = [
  {
    title: "Introduction",
    lectures: [
      {
        title: "Introduction (Do Not Skip This Video)",
        duration: "5:00",
        description: "Overview of the course and objectives.",
      },
    ],
  },
  {
    title: "Setup Hacking Environment",
    lectures: [
      {
        title: "Introduction To Setup Hacking Environment",
        duration: "7:00",
        description: "Overview of setting up the hacking environment.",
      },
      {
        title: "What Is VMware? How To Download And Install VMware?",
        duration: "15:00",
        description: "Explanation of VMware and its installation process.",
      },
      {
        title: "Download & Install Kali Linux 2019.2 & 2020.2",
        duration: "20:00",
        description: "Step-by-step installation of Kali Linux.",
      },
      {
        title: "Download & Install Windows OS In VMware",
        duration: "18:00",
        description: "Guide on installing Windows OS inside VMware.",
      },
      {
        title: "Copy Paste Anything From Virtual Windows To Primary Windows: Download And Install VMware Tools",
        duration: "10:00",
        description: "Installing VMware tools to enable copy-paste functionality.",
      },
      {
        title: "Download & Install Some Important Packages Of Java",
        duration: "12:00",
        description: "Installing essential Java packages for hacking.",
      },
      {
        title: "Download And Install Jar Signer In Kali Linux",
        duration: "15:00",
        description: "Guide to install Jar Signer for securing applications.",
      },
      {
        title: "Installing RATs",
        duration: "10:00",
        description: "Installing Remote Access Trojans (RATs) for hacking.",
      },
      {
        title: "Download & Install Android Emulators in Windows PC",
        duration: "25:00",
        description: "Setting up Android emulators for testing on Windows.",
      },
    ],
  },
  {
    title: "Hacking Android Phone With Normal Payload",
    lectures: [
      {
        title: "Welcome To Hacking Android Phone With Normal Payload",
        duration: "5:00",
        description: "Introduction to hacking Android phones with normal payloads.",
      },
      {
        title: "How To Make Basic Payload Of Metasploit?",
        duration: "20:00",
        description: "Creating a basic payload using Metasploit.",
      },
      {
        title: "How to Make Basic RATs Payload?",
        duration: "15:00",
        description: "Guide on creating RATs payload.",
      },
      {
        title: "How To Make Basic Botnet Payload?",
        duration: "18:00",
        description: "Step-by-step guide to creating a basic botnet payload.",
      },
      {
        title: "Fixing Error: Main Activity Get Older Error",
        duration: "10:00",
        description: "Fix for 'Main Activity Get Older' error.",
      },
      {
        title: "How To Make Basic Payload Of Cypher RAT?",
        duration: "20:00",
        description: "Creating a basic Cypher RAT payload.",
      },
      {
        title: "💥 How to Hack Android 13 and 👁️🕹️ Control Live Screen of Android 📱👨‍💻",
        duration: "30:00",
        description: "Hack Android 13 and control live screen remotely.",
      },
    ],
  },
  {
    title: "Make FUD (Fully Undetectable) Android Payload Bypass All Antivirus",
    lectures: [
      {
        title: "Welcome To FUD Section",
        duration: "10:00",
        description: "Introduction to making fully undetectable (FUD) payloads.",
      },
      {
        title: "Types Of Antivirus Security",
        duration: "15:00",
        description: "Exploring different types of antivirus security.",
      },
      {
        title: "How To Create FUD Android Payload (Concept) (HACK2ED VIDEO)",
        duration: "25:00",
        description: "Guide to creating FUD payloads to bypass antivirus.",
      },
      {
        title: "How To Bypass Spymax From Google Play Protect?",
        duration: "20:00",
        description: "Bypassing Spymax detection in Google Play Protect.",
      },
      {
        title: "How To Bypass Spy Max From AVG/Avast?",
        duration: "18:00",
        description: "Bypassing Spy Max detection in AVG/Avast antivirus.",
      },
      {
        title: "Part 2: How to Bypass Spynote From AVG/Avast",
        duration: "22:00",
        description: "Bypassing Spynote detection in AVG/Avast antivirus.",
      },
      {
        title: "How To Bypass ESET Antivirus",
        duration: "18:00",
        description: "Guide to bypassing ESET antivirus.",
      },
    ],
  },
  {
    title: "Inject Android Malware in Any App",
    lectures: [
      {
        title: "Welcome To Inject Android Malware In Any App",
        duration: "10:00",
        description: "Introduction to injecting malware into Android apps.",
      },
      {
        title: "How to Download and Setup APKTool in Windows (Do Not Skip This Video)",
        duration: "15:00",
        description: "Downloading and setting up APKTool in Windows.",
      },
      {
        title: "Install Some Required Tools In Kali Linux",
        duration: "12:00",
        description: "Installing required tools in Kali Linux for malware injection.",
      },
      {
        title: "[YouTube Video] How to Bind Payload in APK Backdoor by Using Script",
        duration: "20:00",
        description: "Binding payload in APK backdoor using scripts.",
      },
      {
        title: "How to Bind Payload in Any APK Manually (Fix 'Unable to Rebuild APK')",
        duration: "18:00",
        description: "Manually binding payload in APK and fixing rebuild errors.",
      },
      {
        title: "How to Bind Payload in Picsart (Fix 'Unable to Rebuild APK' Part 2)",
        duration: "18:00",
        description: "Binding payload in Picsart app and fixing errors.",
      },
      {
        title: "Part 3: APK Binding (Finding Launchable Activity)",
        duration: "15:00",
        description: "Binding payload by identifying launchable activities.",
      },
      {
        title: "How To Bind Any App With AhMyth Payload?",
        duration: "20:00",
        description: "Binding apps with AhMyth payload.",
      },
      {
        title: "How To Bind Botnet Payload in Any APK",
        duration: "25:00",
        description: "Binding botnet payloads in APKs.",
      },
      {
        title: "A Technique To Bind RAT Payload In Any App",
        duration: "15:00",
        description: "Techniques for binding RAT payload in any app.",
      },
      {
        title: "How To Bind RATs Botnet Payload with MSF Payload?",
        duration: "20:00",
        description: "Binding RATs botnet payload with MSF payload.",
      },
      {
        title: "How To Bind SpyMax Payload In Any APK?",
        duration: "18:00",
        description: "Binding SpyMax payload in APKs.",
      },
    ],
  },
  {
    title: "Hack Android Outside Network",
    lectures: [
      {
        title: "Introduction Of Hack Outside Network",
        duration: "10:00",
        description: "Introduction to hacking outside local networks.",
      },
      {
        title: "Theory Of Hack Outside Network",
        duration: "15:00",
        description: "Understanding the theory of hacking outside networks.",
      },
      {
        title: "Hack Outside With Your Own Wi-Fi Router",
        duration: "25:00",
        description: "Hacking Android devices outside the network using your own Wi-Fi router.",
      },
      {
        title: "[YouTube Video] Hack Outside Network Attack Without Router (Method 1)",
        duration: "22:00",
        description: "Hack Android devices outside the network without a router using Method 1.",
      },
      {
        title: "Hack Outside Network Attack Without Router 1 (Method 2)",
        duration: "20:00",
        description: "Hack Android devices outside the network without a router using Method 2.",
      },
      {
        title: "Hack Outside Network Attack Without Router In Beef Framework (Method 3)",
        duration: "20:00",
        description: "Hacking Android devices outside the network using Beef framework.",
      },
      {
        title: "Hack Outside Network RATs Payload Without Router (Method 4)",
        duration: "25:00",
        description: "Hacking outside network using RAT payload without router.",
      },
      {
        title: "Hack Outside Network Botnet Payload Without Router (Method 4)",
        duration: "20:00",
        description: "Using botnet payload to hack outside networks without router.",
      },
      {
        title: "Hack Outside Network Attack Without Router (V.P.S Port Forwarding Part 1)",
        duration: "22:00",
        description: "Hacking outside networks using VPS port forwarding (Part 1).",
      },
      {
        title: "Hack Outside Network Attack Without Router (V.P.S Port Forwarding Part 2) with a Bonus Tip",
        duration: "25:00",
        description: "Advanced VPS port forwarding with additional tips (Part 2).",
      },
      {
        title: "Free Port Forwarding Using 4 Websites",
        duration: "15:00",
        description: "Free port forwarding using different websites.",
      },
    ],
  },
  {
    title: "Some Social Engineering Techniques to Hack Android",
    lectures: [
      {
        title: "Introduction To Social Engineering Section",
        duration: "5:00",
        description: "Introduction to various social engineering techniques for hacking Android.",
      },
      {
        title: "What Is Attack Cycle In Social Engineering?",
        duration: "10:00",
        description: "Explaining the attack cycle in social engineering techniques.",
      },
      {
        title: "Part 2: APK Binding with Social Engineering [YouTube Video]",
        duration: "15:00",
        description: "APK binding techniques combined with social engineering.",
      },
      {
        title: "Information Gathering Part 1",
        duration: "12:00",
        description: "Basics of gathering information for social engineering attacks.",
      },
      {
        title: "Information Gathering Part 2",
        duration: "10:00",
        description: "Advanced methods of gathering critical information.",
      },
      {
        title: "Information Gathering Part 3",
        duration: "12:00",
        description: "Continuing to gather detailed information for hacking purposes.",
      },
      {
        title: "How To Add Any Website In Payload?",
        duration: "15:00",
        description: "Step-by-step guide to adding a website in the payload.",
      },
      {
        title: "Auto Download And Install Any APK",
        duration: "20:00",
        description: "How to automatically download and install any APK using social engineering.",
      },
      {
        title: "QR Code – Social Engineering Explanation To Hack Android Phone",
        duration: "12:00",
        description: "Using QR codes for social engineering and hacking Android devices.",
      },
      {
        title: "Part 1: Social Engineering With Beef Framework",
        duration: "18:00",
        description: "Social engineering techniques using Beef framework - Part 1.",
      },
      {
        title: "Part 2: Social Engineering With Beef Framework",
        duration: "20:00",
        description: "Further techniques in social engineering using Beef framework - Part 2.",
      },
      {
        title: "Some Social Engineering Tricks To Hack Android [HACK2ED Course Video]",
        duration: "15:00",
        description: "Various social engineering tricks to hack Android devices.",
      },
    ],
  },
  {
    title: "Make Your Android Payload Persistent",
    lectures: [
      {
        title: "Welcome To Android Persistent Section",
        duration: "5:00",
        description: "Introduction to making Android payloads persistent.",
      },
      {
        title: "Some Old Methods We Have Already Learned To Make Payload Persistent",
        duration: "10:00",
        description: "Review of previous methods for making payloads persistent.",
      },
      {
        title: "Auto Start Android Malware (Payload) After Reboot (After Android 5.0)",
        duration: "15:00",
        description: "Enabling auto-start for Android malware after device reboot.",
      },
      {
        title: "How To Make Payload Ultra Persistent?",
        duration: "20:00",
        description: "Advanced methods to make your payload ultra-persistent.",
      },
      {
        title: "How To Start Payload Through Charger Input?",
        duration: "12:00",
        description: "Trigger payload activation through charger input.",
      },
      {
        title: "How To Start Payload Through Headphone/Earphone Input?",
        duration: "10:00",
        description: "Activating payload through headphone/earphone input.",
      },
      {
        title: "How To Start Payload By Wi-Fi?",
        duration: "15:00",
        description: "Activating payload by detecting Wi-Fi connections.",
      },
      {
        title: "How To Start Payload Using Notification?",
        duration: "12:00",
        description: "Triggering payload through Android notifications.",
      },
      {
        title: "How To Start Payload By Calling Victim?",
        duration: "15:00",
        description: "Activating payload by making a phone call to the victim.",
      },
    ],
  },
  {
    title: "Post-Exploitation",
    lectures: [
      {
        title: "Introduction To Post-Exploitation Section",
        duration: "5:00",
        description: "Introduction to post-exploitation techniques.",
      },
      {
        title: "Post-Exploitation RAT 1",
        duration: "15:00",
        description: "Post-exploitation strategies using RATs (Part 1).",
      },
      {
        title: "Post-Exploitation RAT 2",
        duration: "18:00",
        description: "Further techniques in post-exploitation using RATs (Part 2).",
      },
      {
        title: "Post-Exploitation RAT 3",
        duration: "20:00",
        description: "Advanced post-exploitation techniques using RATs (Part 3).",
      },
      {
        title: "Post-Exploitation RAT 4",
        duration: "22:00",
        description: "Maximizing control in post-exploitation using RATs (Part 4).",
      },
      {
        title: "Post-Exploitation Android Debug Bridge",
        duration: "15:00",
        description: "Using Android Debug Bridge (ADB) for post-exploitation tasks.",
      },
    ],
  },
  {
    title: "Some Useful Tricks Regarding Android Payloads",
    lectures: [
      {
        title: "Introduction To Some Useful Tricks Regarding Android Payloads",
        duration: "5:00",
        description: "Introduction to useful tricks for Android payloads.",
      },
      {
        title: "Auto Allow Permissions To Any App",
        duration: "12:00",
        description: "Automatically allow permissions to any app for seamless exploitation.",
      },
      {
        title: "Add Administrator Permission On Any Payload/App",
        duration: "10:00",
        description: "Grant administrator permissions to any payload or app.",
      },
      {
        title: "Insert Fake Calculator In Any Payload/App & Hide Payload From Recent Apps",
        duration: "15:00",
        description: "Inserting a fake calculator and hiding the payload from recent apps.",
      },
      {
        title: "How To Hide IP Address Of Any Payload",
        duration: "12:00",
        description: "Techniques to hide the IP address of payloads.",
      },
      {
        title: "How To Remove Notification Of Any Payload/App?",
        duration: "10:00",
        description: "Removing notifications from payloads or apps to avoid detection.",
      },
      {
        title: "How To Get Pre-Generated Undetectable Payload",
        duration: "15:00",
        description: "Accessing pre-generated undetectable payloads.",
      },
    ],
  },
];


  return (
    <Container id={id} {...rest}>
      <Title>
        Course <span>Content</span>
      </Title>
      <ContentWrapper>
        <AccordionContainer>
          {modules.map((module, index) => (
            <AccordionItem key={index}>
              <AccordionHeader
                onClick={() =>
                  setOpenModule(openModule === index ? -1 : index)
                }
              >
                <div className="left-content">
                  <ChevronDown
                    size={24}
                    style={{
                      transform:
                        openModule === index ? 'rotate(180deg)' : 'rotate(0)',
                      transition: 'transform 0.3s ease',
                      color: '#007aff',
                    }}
                  />
                  <span className="text-lg font-semibold">{module.title}</span>
                </div>
                <div className="right-content">
                  <span>{module.lectures.length} lectures</span>
                  <span className="module-badge">Module {index + 1}</span>
                </div>
              </AccordionHeader>
              <AccordionContent isOpen={openModule === index}>
                {module.lectures.map((lecture, lIndex) => (
                  <LectureItem key={lIndex}>
                    <div className="lecture-info">
                      <PlayCircle size={20} color="#007aff" />
                      <span className="lecture-title">{lecture.title}</span>
                    </div>
                    <div className="lecture-meta">
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem',
                        }}
                      >
                        <Clock size={16} />
                        <span>{lecture.duration}</span>
                      </div>
                      {lecture.locked && <Lock size={16} />}
                    </div>
                  </LectureItem>
                ))}
              </AccordionContent>
            </AccordionItem>
          ))}
        </AccordionContainer>

        <SidebarContainer>
          <CourseImage>
            <img
              src="https://img.youtube.com/vi/Ujuqx25qtDQ/maxresdefault.jpg"
              alt="Course Preview"
            />
            <RatingBadge>
              <Star size={16} className="star-icon" />
              <span className="rating-text">4.9</span>
            </RatingBadge>
          </CourseImage>

          <PriceTag>
            <span className="original-price">₹2000</span>
            <span className="current-price">₹699</span>
            <span className="discount-badge">65.05% OFF</span>
          </PriceTag>

          <EnrollButton
            onClick={handleEnrollClick}
            loading={buttonState === 'loading'}
            success={buttonState === 'success'}
            disabled={buttonState === 'loading' || buttonState === 'success'}
          >
            {buttonState === 'default' && 'Enroll Now'}
            {buttonState === 'loading' && <div className="loader"></div>}
            {buttonState === 'success' && (
              <svg className="success-icon" viewBox="0 0 24 24">
                <polyline points="20 6 9 17 4 12" />
              </svg>
            )}
          </EnrollButton>

          <CourseStats>
            <div className="stat-item">
              <Video size={24} color="#007aff" />
              <span className="stat-value">12+</span>
              <span className="stat-label">Video Hours</span>
            </div>
            <div className="stat-item">
              <BookOpen size={24} color="#007aff" />
              <span className="stat-value">10</span>
              <span className="stat-label">Modules</span>
            </div>
            <div className="stat-item">
              <Users size={24} color="#007aff" />
              <span className="stat-value">10,000+</span>
              <span className="stat-label">Students</span>
            </div>
            <div className="stat-item">
              <Clock size={24} color="#007aff" />
              <span className="stat-value">Lifetime</span>
              <span className="stat-label">Access</span>
            </div>
          </CourseStats>

          <CourseFeatures>
            <h3>What you'll get:</h3>
            <div className="feature-list">
              <div className="feature-item">
                <Check size={20} />
                <span>Full lifetime access</span>
              </div>
              <div className="feature-item">
                <Check size={20} />
                <span>Access on mobile and desktop</span>
              </div>
              <div className="feature-item">
                <Check size={20} />
                <span>Certificate of completion</span>
              </div>
              <div className="feature-item">
                <Check size={20} />
                <span>WhatsApp/Email support</span>
              </div>
              <div className="feature-item">
                <Check size={20} />
                <span>Downloadable resources</span>
              </div>
            </div>
          </CourseFeatures>
        </SidebarContainer>
      </ContentWrapper>
    </Container>
  );
};

export default CourseContentSection;
