import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Gift, Sparkles, ArrowRight, Lock } from 'lucide-react';

const bonusAndroid_shine = keyframes`
  0% { background-position: -200% center; }
  100% { background-position: 200% center; }
`;

const bonusAndroid_float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const bonusAndroid_gradientFlow = keyframes`
  0% { background-position: 0% 50%; }
 50% { background-position: 100% 50%; }
 100% { background-position: 0% 50%; }
`;

const bonusAndroid_pulseGlow = keyframes`
  0% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0.4); }
 70% { box-shadow: 0 0 0 10px rgba(0, 122, 255, 0); }
 100% { box-shadow: 0 0 0 0 rgba(0, 122, 255, 0); }
`;

const BonusAndroid_Container = styled.section`
  padding: 6rem 2rem;
  background-color: #0a0a0a;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(0, 122, 255, 0),
      #007aff,
      rgba(0, 122, 255, 0)
    );
    background-size: 200% 100%;
    animation: ${bonusAndroid_shine} 3s infinite linear;
  }

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 3rem 1rem;
  }
`;

const BonusAndroid_Title = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 2rem;
  }
`;

const BonusAndroid_MainTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #007aff, #5856d6, #007aff);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${bonusAndroid_gradientFlow} 3s ease infinite;

  @media (max-width: 1024px) {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const BonusAndroid_SubTitle = styled.p`
  color: #b0b0b0;
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const BonusAndroid_Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2.5rem;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1rem;

  @media (max-width: 768px) {
    gap: 2rem;
  }

  @media (max-width: 480px) {
    gap: 1.5rem;
  }
`;

const BonusAndroid_Card = styled.div`
  position: relative;
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.01)
  );
  border-radius: 24px;
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.05);
  transition: all 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 4px;
    background: linear-gradient(90deg, #007aff, #5856d6);
    opacity: 0;
    transition: opacity 0.3s ease;
  }

  &:hover {
    transform: translateY(-8px);
    border-color: rgba(0, 122, 255, 0.2);
    box-shadow:
      0 10px 30px -5px rgba(0, 0, 0, 0.3),
      0 0 40px rgba(0, 122, 255, 0.1);

    &::before {
      opacity: 1;
    }
  }

  @media (max-width: 480px) {
    &:hover {
      transform: none;
    }
  }
`;

const BonusAndroid_CardInner = styled.div`
  padding: 2.5rem;
  position: relative;
  z-index: 1;

  @media (max-width: 768px) {
    padding: 2rem;
  }

  @media (max-width: 480px) {
    padding: 1.5rem;
  }
`;

const BonusAndroid_BonusLabel = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  background: linear-gradient(
    90deg,
    rgba(0, 122, 255, 0.1),
    rgba(88, 86, 214, 0.1)
  );
  padding: 0.5rem 1rem;
  border-radius: 50px;
  margin-bottom: 1.5rem;

  @media (max-width: 480px) {
    padding: 0.4rem 0.8rem;
    margin-bottom: 1rem;
  }
`;

const BonusAndroid_BonusNumber = styled.span`
  color: #007aff;
  font-weight: 700;
  font-size: 0.875rem;

  @media (max-width: 480px) {
    font-size: 0.8rem;
  }
`;

const BonusAndroid_BonusIcon = styled(Gift)`
  color: #007aff;
  width: 18px;
  height: 18px;
`;

const BonusAndroid_BonusTitle = styled.h3`
  color: #ffffff;
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.4;

  @media (max-width: 768px) {
    font-size: 1.3rem;
  }

  @media (max-width: 480px) {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }
`;

const BonusAndroid_PriceTag = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;

  @media (max-width: 480px) {
    margin-top: 1.5rem;
    gap: 0.75rem;
  }
`;

const BonusAndroid_Worth = styled.span`
  color: #b0b0b0;
  font-size: 1rem;
  font-weight: 500;

  @media (max-width: 480px) {
    font-size: 0.9rem;
  }
`;

const BonusAndroid_Price = styled.div`
  font-size: 2rem;
  font-weight: 800;
  color: #31d36c;
  text-shadow: 0 0 20px rgba(49, 211, 108, 0.3);

  span {
    font-size: 1.25rem;
    margin-right: 0.25rem;

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 480px) {
    font-size: 1.5rem;
  }
`;

const BonusAndroid_FreeTag = styled.div`
  position: absolute;
  top: 2rem;
  right: -4rem;
  background: linear-gradient(90deg, #ff2d55, #ff2d55);
  padding: 0.5rem 4rem;
  transform: rotate(45deg);
  font-size: 0.875rem;
  font-weight: 700;
  color: white;
  box-shadow: 0 2px 10px rgba(255, 45, 85, 0.3);
  animation: ${bonusAndroid_pulseGlow} 2s infinite;

  @media (max-width: 768px) {
    top: 1.5rem;
    right: -3rem;
    padding: 0.4rem 3rem;
  }

  @media (max-width: 480px) {
    top: 1rem;
    right: -2.5rem;
    padding: 0.3rem 2.5rem;
    font-size: 0.75rem;
  }
`;

const BonusAndroid_AccessButton = styled.a`
  width: 100%;
  margin-top: 2rem;
  padding: 1rem;
  border: none;
  border-radius: 12px;
  background: linear-gradient(90deg, #007aff, #5856d6);
  color: white;
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  text-decoration: none;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 122, 255, 0.3);
  }

  svg {
    transition: transform 0.3s ease;
  }

  &:hover svg {
    transform: translateX(3px);
  }

  @media (max-width: 480px) {
    margin-top: 1.5rem;
    padding: 0.75rem;
    font-size: 0.9rem;
  }
`;

const BonusAndroid_SparkleIcon = styled(Sparkles)`
  position: absolute;
  ${(props) => props.position};
  color: #007aff;
  opacity: 0.5;
  animation: ${bonusAndroid_float} 3s ease-in-out infinite;
  animation-delay: ${(props) => props.delay || '0s'};

  @media (max-width: 768px) {
    display: none;
  }
`;

const BonusAndroid_BonusSection = () => {
  const cardLink = 'https://web.tevh.in/checkout-android-course';

  const bonuses = [
    {
      id: 1,
      title: 'Spymax Binding Method',
      worth: '4,997',
    },
    {
      id: 2,
      title: 'Trick To Bypass All Antiviruses For Long Time',
      worth: '4,500',
    },
    {
      id: 3,
      title: 'Start Payload (Malware) Using Notification?',
      worth: '5,500',
    },
    {
      id: 4,
      title: 'Start Payload (Malware) By Calling Victim?',
      worth: '5,500',
    },
    {
      id: 5,
      title: 'How To Give Permissions To Any App Without Touch',
      worth: '4,250',
    },
    {
      id: 6,
      title: 'Auto Download & Install Any Apk In Any Android Phone',
      worth: '4,250',
    },
  ];

  return (
    <BonusAndroid_Container>
      <BonusAndroid_SparkleIcon
        position="top: 10%; left: 5%;"
        delay="0s"
        size={20}
      />
      <BonusAndroid_SparkleIcon
        position="top: 20%; right: 10%;"
        delay="0.5s"
        size={24}
      />
      <BonusAndroid_SparkleIcon
        position="bottom: 15%; left: 15%;"
        delay="1s"
        size={16}
      />

      <BonusAndroid_Title>
        <BonusAndroid_MainTitle>Some Free Bonuses</BonusAndroid_MainTitle>
        <BonusAndroid_SubTitle>
          Unlock exclusive content worth ₹30,000+ at no additional cost
        </BonusAndroid_SubTitle>
      </BonusAndroid_Title>

      <BonusAndroid_Grid>
        {bonuses.map((bonus) => (
          <BonusAndroid_Card key={bonus.id}>
            <BonusAndroid_FreeTag>FREE</BonusAndroid_FreeTag>
            <BonusAndroid_CardInner>
              <BonusAndroid_BonusLabel>
                <BonusAndroid_BonusIcon />
                <BonusAndroid_BonusNumber>
                  Bonus #{bonus.id}
                </BonusAndroid_BonusNumber>
              </BonusAndroid_BonusLabel>

              <BonusAndroid_BonusTitle>{bonus.title}</BonusAndroid_BonusTitle>

              <BonusAndroid_PriceTag>
                <BonusAndroid_Worth>Worth</BonusAndroid_Worth>
                <BonusAndroid_Price>
                  <span>₹</span>
                  {bonus.worth}
                </BonusAndroid_Price>
              </BonusAndroid_PriceTag>

              <BonusAndroid_AccessButton href={cardLink}>
                <Lock size={18} />
                Unlock Now
                <ArrowRight size={18} />
              </BonusAndroid_AccessButton>
            </BonusAndroid_CardInner>
          </BonusAndroid_Card>
        ))}
      </BonusAndroid_Grid>
    </BonusAndroid_Container>
  );
};

export default BonusAndroid_BonusSection;
