import React, { useState } from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';
import { Package, Monitor, GraduationCap, Gift, Check } from 'lucide-react';

// Global Styles with Smooth Scrolling and Reduced Motion Preference
const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    background-color: #0a0a0a;
  }
  
  /* Enable smooth scrolling */
  html {
    scroll-behavior: smooth;
  }

  /* Respect user's reduced motion preferences */
  @media (prefers-reduced-motion: reduce) {
    *, *::before, *::after {
      animation: none !important;
      transition: none !important;
    }
  }
`;

// Animations
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.98); }
  100% { transform: scale(1); }
`;

const loaderSpin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const checkmarkDraw = keyframes`
  0% {
    stroke-dashoffset: 50;
    opacity: 0;
  }
  60% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
  100% {
    stroke-dashoffset: 0;
    opacity: 1;
  }
`;

// Styled Components
const HeroSection = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 1rem;
  color: #ffffff;

  @media (min-width: 768px) {
    padding: 4rem 2rem;
  }

  @media (min-width: 1200px) {
    padding: 5rem 3rem;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  gap: 3rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    align-items: center;
  }
`;

const TextContent = styled.div`
  flex: 1;
  animation: ${fadeIn} 0.8s ease-out;
`;

const Title = styled.h1`
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 1rem;
  line-height: 1.2;

  span {
    background: linear-gradient(90deg, #007aff, #5856d6);
    background-size: 200% 200%;
    animation: ${gradientAnimation} 3s ease infinite;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  @media (min-width: 768px) {
    font-size: 3rem;
  }

  @media (min-width: 1200px) {
    font-size: 3.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.125rem;
  color: #b0b0b0;
  margin-bottom: 1.5rem;
  max-width: 500px;
  line-height: 1.6;

  @media (min-width: 768px) {
    font-size: 1.25rem;
  }
`;

const FeatureList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin-bottom: 2rem;
`;

const FeatureItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
  font-size: 1rem;
  color: #ffffff;

  &:before {
    content: '✓';
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background: #007aff;
    border-radius: 12px; /* Slightly curved */
    margin-right: 1rem;
    font-size: 0.875rem;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
  }
`;

const ButtonGroup = styled.div`
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
  flex-wrap: wrap;
`;

// Button Styles
const Button = styled.button`
  padding: 1rem 2rem;
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.5rem; /* Subtle curve */
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  min-width: 200px; /* Equal width */
  position: relative;
  border: none;
  background: none;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.6;
  }

  &:active:not(:disabled) {
    animation: ${pulseAnimation} 0.3s ease-in-out;
  }

  @media (min-width: 768px) {
    font-size: 1.125rem;
    flex: 0 1 auto;
  }
`;

const PrimaryButton = styled(Button)`
  background: linear-gradient(90deg, #007aff, #5856d6);
  background-size: 200% 200%;
  animation: ${gradientAnimation} 3s ease infinite;
  color: white;
  width: ${(props) => (props.isLoading || props.isSuccess ? '60px' : '200px')}; /* Fixed width */
  height: ${(props) => (props.isLoading || props.isSuccess ? '60px' : 'auto')};
  padding: ${(props) => (props.isLoading || props.isSuccess ? '0' : '1rem 2rem')};
  border-radius: 0.5rem; /* Subtle curve */
  box-shadow: 0 4px 6px rgba(0, 122, 255, 0.3);
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    opacity: 0.9;
    transform: translateY(-3px);
    box-shadow: 0 6px 12px rgba(0, 122, 255, 0.4);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.5);
  }
`;

const SuccessIcon = styled(Check)`
  stroke: #ffffff; /* White color for checkmark */
  stroke-width: 4px;
  fill: none;
  width: 24px;
  height: 24px;
  animation: ${checkmarkDraw} 0.3s forwards; /* Faster animation */
`;

const Loader = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid rgba(255, 255, 255, 0.6);
  border-top: 3px solid #ffffff;
  border-radius: 50%;
  animation: ${loaderSpin} 0.3s linear infinite; /* Faster spin */
`;

const SecondaryButton = styled(Button)`
  background: transparent;
  color: #007aff;
  border: 2px solid #007aff;
  border-radius: 0.5rem;
  transition: all 0.3s ease;

  &:hover:not(:disabled) {
    background-color: rgba(0, 122, 255, 0.1);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 122, 255, 0.2);
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(0, 122, 255, 0.5);
  }
`;

// Video Styles
const VideoContainer = styled.div`
  flex: 1;
  aspect-ratio: 16 / 9;
  border-radius: 1rem;
  position: relative;
  padding: 2px;
  background: linear-gradient(45deg, #007aff, #5856d6, #ff2d55, #007aff);
  background-size: 400% 400%;
  animation: ${gradientAnimation} 15s ease infinite, ${fadeIn} 0.8s ease-out 0.2s backwards;
  box-shadow: 0 4px 20px rgba(0, 122, 255, 0.2);
`;

const VideoWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background: #000;
  border-radius: 0.9rem;
  overflow: hidden;
`;

// Benefits Styles
const BenefitsList = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 2rem;
  justify-content: center;
  max-width: 1200px;
  margin: 4rem auto;
  padding: 0 1rem;

  @media (max-width: 480px) {
    grid-template-columns: 1fr;
    gap: 1rem;
  }

  @media (min-width: 481px) and (max-width: 1024px) {
    grid-template-columns: repeat(2, 1fr);
    padding: 0 2rem;
  }

  @media (min-width: 1025px) {
    grid-template-columns: repeat(4, 1fr);
    padding: 0 3rem;
  }
`;

const BenefitCard = styled.div`
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1rem;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: all 0.3s ease;
  cursor: pointer;

  &:hover {
    transform: translateY(-5px);
    box-shadow: 0 10px 20px rgba(0, 122, 255, 0.1);
    background: rgba(255, 255, 255, 0.1);
  }

  @media (max-width: 768px) {
    padding: 1rem;
  }
`;

const BenefitIcon = styled.div`
  color: #007aff;
  margin-bottom: 1rem;

  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }
`;

const BenefitTitle = styled.h3`
  color: #ffffff;
  font-size: 1.25rem;
  margin-bottom: 0.5rem;

  @media (max-width: 768px) {
    font-size: 1.1rem;
    margin-bottom: 0.4rem;
  }
`;

const BenefitText = styled.p`
  color: #b0b0b0;
  font-size: 0.875rem;

  @media (max-width: 768px) {
    font-size: 0.8rem;
  }
`;

// Main App Component
const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEnrollClick = () => {
    setIsLoading(true);

    // Simulate loading delay (e.g., API call)
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);

      // After showing checkmark for 1 second, redirect
      setTimeout(() => {
        window.location.href = 'https://web.tevh.in/checkout-android-course';
      }, 1000); // Faster redirection
    }, 800); // Faster loading time
  };

  return (
    <>
      <GlobalStyle />
      <HeroSection>
        <Content>
          <TextContent>
            <Title>
              Master <span>Android Hacking</span>
            </Title>
            <Subtitle>
              Discover real-world hacking skills used by professionals to secure and exploit Android devices step-by-step.
            </Subtitle>
            <FeatureList>
              <FeatureItem>Lifetime Course Access</FeatureItem>
              <FeatureItem>10,000+ Students Enrolled</FeatureItem>
              <FeatureItem>Perfect for Beginners</FeatureItem>
              <FeatureItem>Hindi Language Learning</FeatureItem>
              <FeatureItem>Pre-Recorded Course</FeatureItem>
            </FeatureList>
            <ButtonGroup>
              <PrimaryButton
                onClick={handleEnrollClick}
                disabled={isLoading || isSuccess}
                isLoading={isLoading}
                isSuccess={isSuccess}
                aria-label="Enroll Now"
              >
                {isLoading ? (
                  <Loader />
                ) : isSuccess ? (
                  <SuccessIcon />
                ) : (
                  'Enroll Now'
                )}
              </PrimaryButton>
              {/* 
                "Course Content" Button:
                - Changed href to "#course-content" for in-page scrolling.
                - Removed target="_blank" and rel attributes to prevent opening in a new tab.
              */}
              <SecondaryButton as="a" href="#course-content">
                Course Content
              </SecondaryButton>
            </ButtonGroup>
          </TextContent>
          <VideoContainer>
            <VideoWrapper>
              <iframe
                width="100%"
                height="100%"
                src="https://www.youtube.com/embed/Ujuqx25qtDQ"
                title="Android Hacking Course Preview"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              ></iframe>
            </VideoWrapper>
          </VideoContainer>
        </Content>
        <BenefitsList>
          <BenefitCard>
            <BenefitIcon>
              <Package size={40} />
            </BenefitIcon>
            <BenefitTitle>Software Included</BenefitTitle>
            <BenefitText>
              All required software and operating systems are included in the course for free.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <BenefitIcon>
              <Monitor size={40} />
            </BenefitIcon>
            <BenefitTitle>Multiple OS Coverage</BenefitTitle>
            <BenefitText>
              We cover Windows, Linux, and Android operating systems in this course.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <BenefitIcon>
              <GraduationCap size={40} />
            </BenefitIcon>
            <BenefitTitle>Basic to Advanced</BenefitTitle>
            <BenefitText>
              Perfect for beginners and those looking to master advanced techniques.
            </BenefitText>
          </BenefitCard>
          <BenefitCard>
            <BenefitIcon>
              <Gift size={40} />
            </BenefitIcon>
            <BenefitTitle>Premium Bonuses</BenefitTitle>
            <BenefitText>
              Get bonuses worth ₹10,000 absolutely free to enhance your learning journey.
            </BenefitText>
          </BenefitCard>
        </BenefitsList>
      </HeroSection>
    </>
  );
};

export default App;
