import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { Check } from 'lucide-react';

// Animations
const demovideo_gradientMove = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const demovideo_fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const demovideo_pulseAnimation = keyframes`
  0% { transform: scale(1); }
  50% { transform: scale(0.95); }
  100% { transform: scale(1); }
`;

const demovideo_loadingAnimation = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const demovideo_successAnimation = keyframes`
  0% { transform: scale(0); }
  70% { transform: scale(1.2); }
  100% { transform: scale(1); }
`;

const DemoVideoSection = styled.section`
  background-color: #0a0a0a;
  position: relative;
  padding: 3rem 0;
  min-height: auto;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(90deg, 
      transparent 0%, 
      rgba(0, 122, 255, 0.3) 50%, 
      transparent 100%
    );
  }

  @media (max-width: 768px) {
    padding: 2rem 0;
  }
`;

const DemoContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 2rem;
  
  @media (max-width: 768px) {
    padding: 0 1rem;
  }
`;

const DemoTitle = styled.div`
  font-family: "Poppins", sans-serif;
  font-size: clamp(2.5rem, 5vw, 3.5rem);
  font-weight: 700;
  text-align: center;
  margin-bottom: 1rem;
  color: #ffffff;
  animation: ${demovideo_fadeIn} 0.6s ease-out;

  span {
    color: #007aff;
    margin-left: 0.5rem;
    background: linear-gradient(90deg, #007aff, #5856d6);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 200% auto;
    animation: ${demovideo_gradientMove} 3s ease infinite;
  }
`;

const DemoSubtitle = styled.div`
  text-align: center;
  margin: 1.5rem auto 3rem;
  padding: 0.75rem 1rem;
  max-width: 800px;
  border: 2px dashed rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  font-size: clamp(1rem, 2vw, 1.2rem);
  color: rgba(255, 255, 255, 0.9);
  animation: ${demovideo_fadeIn} 0.6s ease-out 0.2s backwards;

  strong {
    color: #FCA311;
  }
`;

const DemoContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 3rem;
  align-items: flex-start;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
    gap: 2rem;
  }
`;

const DemoFeatures = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

const DemoFeatureCard = styled.div`
  background: rgba(0, 0, 0, 0.4);
  border: 2px solid rgba(42, 255, 172, 0.15);
  border-radius: 16px;
  padding: 1.5rem;
  display: flex;
  gap: 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  animation: ${demovideo_fadeIn} 0.6s ease-out backwards;
  animation-delay: ${props => props.index * 0.2}s;

  &:hover {
    transform: translateX(10px);
    border-color: rgba(42, 255, 172, 0.3);
    background: rgba(0, 0, 0, 0.5);
    box-shadow: 0 8px 20px rgba(0, 122, 255, 0.1);
  }
`;

const DemoFeatureIcon = styled.div`
  width: 32px;
  height: 32px;
  background: #007aff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: all 0.3s ease;

  ${DemoFeatureCard}:hover & {
    transform: scale(1.1);
  }
`;

const DemoFeatureText = styled.div`
  strong {
    display: block;
    color: #FCA311;
    font-size: 1.1rem;
    margin-bottom: 0.5rem;
    font-weight: 600;
  }

  p {
    color: rgba(255, 255, 255, 0.7);
    line-height: 1.6;
    margin: 0;
  }
`;

const DemoVideoContainer = styled.div`
  position: relative;
  padding: 2px;
  border-radius: 1rem;
  background: linear-gradient(45deg, #007aff, #5856d6, #ff2d55, #007aff);
  background-size: 400% 400%;
  animation: ${demovideo_gradientMove} 15s ease infinite;
  box-shadow: 0 4px 20px rgba(0, 122, 255, 0.2);

  &::after {
    content: '';
    position: absolute;
    inset: -4px;
    border-radius: 1.1rem;
    padding: 4px;
    background: linear-gradient(45deg, 
      rgba(255, 255, 255, 0.1), 
      rgba(255, 255, 255, 0.05)
    );
    -webkit-mask: 
      linear-gradient(#fff 0 0) content-box, 
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
`;

const DemoVideoWrapper = styled.div`
  position: relative;
  padding-top: 56.25%;
  border-radius: 0.9rem;
  overflow: hidden;
  background: #000;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`;

const DemoEnrollButton = styled.button`
  width: 100%;
  margin-top: 2rem;
  min-height: 80px;
  padding: 1.25rem 2rem;
  background: linear-gradient(90deg, #007aff, #5856d6);
  background-size: 200% auto;
  animation: ${demovideo_gradientMove} 3s ease infinite;
  border: none;
  border-radius: 12px;
  color: white;
  font-weight: 600;
  font-size: 1.25rem;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;

  &:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 25px rgba(0, 122, 255, 0.3);
  }

  &:active {
    animation: ${demovideo_pulseAnimation} 0.3s ease-in-out;
  }

  ${props => props.isLoading || props.isSuccess ? `
    width: 100%;
    padding: 1.25rem;
  ` : ''}

  &:disabled {
    cursor: not-allowed;
    opacity: 0.9;
  }
`;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: inherit;
`;

const DemoLoader = styled.div`
  width: 24px;
  height: 24px;
  border: 3px solid #ffffff;
  border-top: 3px solid transparent;
  border-radius: 50%;
  animation: ${demovideo_loadingAnimation} 1s linear infinite;
`;

const ButtonContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  opacity: ${props => props.isLoading || props.isSuccess ? 0 : 1};
  transition: opacity 0.3s ease;
`;

const PriceText = styled.span`
  font-size: 0.9rem;
  opacity: 0.9;

  strike {
    opacity: 0.7;
    margin-right: 0.5rem;
  }
`;

const DemoVideo = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleEnrollClick = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setIsSuccess(true);
      setTimeout(() => {
        window.open("https://web.tevh.in/checkout-android-course", "_blank");
        setIsSuccess(false);
      }, 1000);
    }, 2000);
  };

  return (
    <DemoVideoSection>
      <DemoContainer>
        <DemoTitle>
          Demo<span>Video!</span>
        </DemoTitle>
        <DemoSubtitle>
          Watch the glimpse of the course before enrolling – <strong>What you can do after enrolling.</strong>
        </DemoSubtitle>

        <DemoContent>
          <DemoFeatures>
            <DemoFeatureCard index={1}>
              <DemoFeatureIcon>
                <Check size={18} color="#000000" />
              </DemoFeatureIcon>
              <DemoFeatureText>
                <strong>Genuine things have been taught</strong>
                <p>When it comes to hacking people are taught fake things but in this course, you will learn real android hacking.</p>
              </DemoFeatureText>
            </DemoFeatureCard>

            <DemoFeatureCard index={2}>
              <DemoFeatureIcon>
                <Check size={18} color="#000000" />
              </DemoFeatureIcon>
              <DemoFeatureText>
                <strong>You will know how things work</strong>
                <p>Most people in the cyber security field will not tell you about how the thing actually works. In this course you will learn exactly how things work deeply.</p>
              </DemoFeatureText>
            </DemoFeatureCard>

            <DemoEnrollButton
              onClick={handleEnrollClick}
              disabled={isLoading || isSuccess}
              isLoading={isLoading}
              isSuccess={isSuccess}
            >
              {isLoading && (
                <LoaderWrapper>
                  <DemoLoader />
                </LoaderWrapper>
              )}
              {isSuccess && (
                <LoaderWrapper>
                  <Check size={24} color="#ffffff" />
                </LoaderWrapper>
              )}
              <ButtonContent isLoading={isLoading} isSuccess={isSuccess}>
                <span>Enroll Now to Learn Android Hacking</span>
                <PriceText>
                  Lifetime Access at just <strike>₹ 6,000</strike> ₹699
                </PriceText>
              </ButtonContent>
            </DemoEnrollButton>
          </DemoFeatures>

          <DemoVideoContainer>
            <DemoVideoWrapper>
              <iframe 
                src="https://www.youtube.com/embed/WRJpy6cJ7do"
                title="Android Hacking Course Preview"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                loading="lazy"
              />
            </DemoVideoWrapper>
          </DemoVideoContainer>
        </DemoContent>
      </DemoContainer>
    </DemoVideoSection>
  );
};

export default DemoVideo;