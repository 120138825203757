import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import WhatYouWillLearn from './whatyouwilllearn';
import DemoVideo from './DemoVideo';
import CourseContentSection from './CourseContentSection';
import BonusSection from './BonusSection';
import FAQSection from './FAQSection';
import FooterSection from './FooterSection';
import MobileStickyBar from './MobileStickyBar';

// Facebook Pixel ID and Microsoft Clarity Project ID
const FACEBOOK_PIXEL_ID = '5608259589212115';
const MICROSOFT_CLARITY_PROJECT_ID = 'nmqoyj8cwv';

// AnalyticsInitializer Component to handle side effects
const AnalyticsInitializer = () => {
  useEffect(() => {
    // Initialize Facebook Pixel
    (function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod
          ? n.callMethod.apply(n, arguments)
          : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = true;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = true;
      t.src = 'https://connect.facebook.net/en_US/fbevents.js';
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    })(window, document, 'script');

    // Initialize the Facebook Pixel with your Pixel ID
    window.fbq('init', FACEBOOK_PIXEL_ID);
    window.fbq('track', 'PageView');

    // Initialize Microsoft Clarity
    (function (c, l, a, r, i, t, y) {
      c[a] =
        c[a] ||
        function () {
          (c[a].q = c[a].q || []).push(arguments);
        };
      t = l.createElement(r);
      t.async = 1;
      t.src = `https://www.clarity.ms/tag/${MICROSOFT_CLARITY_PROJECT_ID}`;
      y = l.getElementsByTagName(r)[0];
      y.parentNode.insertBefore(t, y);
    })(window, document, 'clarity', 'script');

    // Inject global styles
    const style = document.createElement('style');
    style.textContent = `
      /* Enable smooth scrolling */
      html {
        scroll-behavior: smooth;
      }
    `;
    document.head.appendChild(style);

    // Cleanup function to revoke Facebook Pixel consent and remove styles
    return () => {
      if (window.fbq) {
        window.fbq('consent', 'revoke');
      }
      if (style.parentNode) {
        style.parentNode.removeChild(style);
      }
    };
  }, []);

  return null; // This component doesn't render anything
};

// Create root element for rendering
const root = ReactDOM.createRoot(document.getElementById('root'));

// Render the app with all components and AnalyticsInitializer
root.render(
  <React.StrictMode>
    <AnalyticsInitializer />
    <div style={{ paddingBottom: 'env(safe-area-inset-bottom, 140px)' }}>
      <App />
      <WhatYouWillLearn />
      <DemoVideo />
      <BonusSection />
      {/* Assign an ID to CourseContentSection for scrolling */}
      <CourseContentSection id="course-content" />
      <FAQSection />
      <FooterSection />
      <MobileStickyBar />
    </div>
  </React.StrictMode>
);
