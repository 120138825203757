import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import {
  ChevronDown,
  HelpCircle,
  Clock,
  Book,
  Globe,
  Shield,
  Monitor,
  MessageCircle,
} from 'lucide-react';

const faqAndroid_gradientFlow = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const faqAndroid_float = keyframes`
  0% { transform: translateY(0px); }
  50% { transform: translateY(-10px); }
  100% { transform: translateY(0px); }
`;

const FaqAndroid_Container = styled.section`
  padding: 6rem 2rem;
  background-color: #0a0a0a;
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: linear-gradient(
      90deg,
      rgba(0, 122, 255, 0),
      #007aff,
      rgba(0, 122, 255, 0)
    );
  }

  @media (max-width: 768px) {
    padding: 4rem 1rem;
  }

  @media (max-width: 480px) {
    padding: 3rem 1rem;
  }
`;

const FaqAndroid_Title = styled.div`
  text-align: center;
  margin-bottom: 4rem;

  @media (max-width: 768px) {
    margin-bottom: 3rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 2rem;
  }
`;

const FaqAndroid_MainTitle = styled.h2`
  font-size: 3.5rem;
  font-weight: 800;
  margin-bottom: 1rem;
  background: linear-gradient(90deg, #007aff, #5856d6, #007aff);
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: ${faqAndroid_gradientFlow} 3s ease infinite;

  @media (max-width: 1024px) {
    font-size: 3rem;
  }

  @media (max-width: 768px) {
    font-size: 2.5rem;
  }

  @media (max-width: 480px) {
    font-size: 2rem;
  }
`;

const FaqAndroid_SubTitle = styled.p`
  color: #b0b0b0;
  font-size: 1.25rem;
  max-width: 600px;
  margin: 0 auto;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (max-width: 480px) {
    font-size: 1rem;
  }
`;

const FaqAndroid_FAQWrapper = styled.div`
  max-width: 900px;
  margin: 0 auto;
  display: grid;
  gap: 1.5rem;

  @media (max-width: 768px) {
    gap: 1rem;
  }

  @media (max-width: 480px) {
    gap: 0.75rem;
  }
`;

const FaqAndroid_FAQItem = styled.div`
  background: linear-gradient(
    145deg,
    rgba(255, 255, 255, 0.03),
    rgba(255, 255, 255, 0.01)
  );
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  overflow: hidden;
  transition: all 0.3s ease;

  &:hover {
    border-color: rgba(0, 122, 255, 0.2);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  }
`;

const FaqAndroid_QuestionHeader = styled.div`
  padding: 1.5rem;
  display: flex;
  align-items: flex-start;
  gap: 1rem;
  cursor: pointer;
  user-select: none;

  .faq-android_icon-wrapper {
    background: rgba(0, 122, 255, 0.1);
    padding: 0.5rem;
    border-radius: 12px;
    color: #007aff;
    flex-shrink: 0;
  }

  .faq-android_content {
    flex: 1;
  }

  .faq-android_question {
    color: #ffffff;
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    line-height: 1.4;

    @media (max-width: 768px) {
      font-size: 1.1rem;
    }

    @media (max-width: 480px) {
      font-size: 1rem;
    }
  }

  @media (max-width: 768px) {
    padding: 1.25rem;
    gap: 0.75rem;
  }

  @media (max-width: 480px) {
    padding: 1rem;
    gap: 0.5rem;
  }
`;

const FaqAndroid_ChevronIcon = styled(ChevronDown)`
  transition: transform 0.3s ease;
  color: #007aff;
  flex-shrink: 0;

  ${(props) => props.isOpen && 'transform: rotate(180deg);'}
`;

const FaqAndroid_Answer = styled.div`
  max-height: ${(props) => (props.isOpen ? '1000px' : '0')};
  opacity: ${(props) => (props.isOpen ? '1' : '0')};
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  padding: ${(props) =>
    props.isOpen ? '0 1.5rem 1.5rem 4rem' : '0 1.5rem 0 4rem'};
  color: #b0b0b0;
  line-height: 1.6;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 1rem 0;

    li {
      position: relative;
      padding-left: 1.5rem;
      margin-bottom: 0.75rem;

      &::before {
        content: '•';
        color: #007aff;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }

  strong {
    color: #ffffff;
  }

  @media (max-width: 768px) {
    padding: ${(props) =>
      props.isOpen ? '0 1.25rem 1.25rem 3rem' : '0 1.25rem 0 3rem'};

    ul li {
      padding-left: 1.25rem;
    }
  }

  @media (max-width: 480px) {
    padding: ${(props) =>
      props.isOpen ? '0 1rem 1rem 2rem' : '0 1rem 0 2rem'};

    ul li {
      padding-left: 1rem;
    }
  }
`;

const FaqAndroid_IconContainer = styled.div`
  position: absolute;
  color: rgba(0, 122, 255, 0.1);
  animation: ${faqAndroid_float} 3s ease-in-out infinite;
  animation-delay: ${(props) => props.delay || '0s'};
  z-index: 0;

  @media (max-width: 768px) {
    display: none;
  }
`;

const FAQSection = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      icon: <Book size={24} />,
      question: 'What Are The Pre-Requisites Of The Course?',
      answer: (
        <ul>
          <li>You need to know how to use a Windows PC.</li>
          <li>Basic Linux knowledge.</li>
          <li>Hindi language proficiency.</li>
        </ul>
      ),
    },
    {
      icon: <Clock size={24} />,
      question:
        'How Long Will It Take To Get Access To The Course? And How Can I Access It?',
      answer: (
        <>
          On the checkout page, you have to fill out an email.
          <br />
          After successful payment, you will receive an email containing a login
          link with a username and password.
          <br />
          You have to use that to log in.
        </>
      ),
    },
    {
      icon: <Shield size={24} />,
      question:
        'Will This Course Work If I Am Totally New To Ethical Hacking?',
      answer:
        'It is specially designed for beginners who want to start Ethical Hacking. If you are one, Enroll Now!',
    },
    {
      icon: <Monitor size={24} />,
      question:
        'Do I Need To Be Tech-Savvy To Understand The Content Of The Course?',
      answer:
        'No, you just have to know how to operate a computer system and have strong willpower. In some videos of the course, you need basics of Kali Linux.',
    },
    {
      icon: <Globe size={24} />,
      question:
        'Everything Is Available On YouTube. Then Why Should I Go For This Course?',
      answer:
        'Yes, you are correct. The internet has all the information which you can access for free. But they are not organized!',
    },
  ];

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <FaqAndroid_Container>
      <FaqAndroid_IconContainer style={{ top: '10%', left: '5%' }} delay="0s">
        <HelpCircle size={40} />
      </FaqAndroid_IconContainer>
      <FaqAndroid_IconContainer
        style={{ top: '20%', right: '10%' }}
        delay="0.5s"
      >
        <MessageCircle size={48} />
      </FaqAndroid_IconContainer>
      <FaqAndroid_IconContainer
        style={{ bottom: '15%', left: '15%' }}
        delay="1s"
      >
        <HelpCircle size={32} />
      </FaqAndroid_IconContainer>

      <FaqAndroid_Title>
        <FaqAndroid_MainTitle>Frequently Asked Questions</FaqAndroid_MainTitle>
        <FaqAndroid_SubTitle>
          Got questions? We've got answers! Check out our most common queries
          below.
        </FaqAndroid_SubTitle>
      </FaqAndroid_Title>

      <FaqAndroid_FAQWrapper>
        {faqs.map((faq, index) => (
          <FaqAndroid_FAQItem key={index}>
            <FaqAndroid_QuestionHeader onClick={() => toggleFAQ(index)}>
              <div className="faq-android_icon-wrapper">{faq.icon}</div>
              <div className="faq-android_content">
                <div className="faq-android_question">{faq.question}</div>
              </div>
              <FaqAndroid_ChevronIcon
                size={24}
                isOpen={openIndex === index}
              />
            </FaqAndroid_QuestionHeader>
            <FaqAndroid_Answer isOpen={openIndex === index}>
              {faq.answer}
            </FaqAndroid_Answer>
          </FaqAndroid_FAQItem>
        ))}
      </FaqAndroid_FAQWrapper>
    </FaqAndroid_Container>
  );
};

export default FAQSection;
